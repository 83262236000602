const actionTypes = {
  LOGIN: 'LOGIN',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',

  GET_POSTS: 'GET_POSTS',
  GET_POSTS_WITH_LABEL: 'GET_POSTS_WITH_LABEL',
  GET_POSTS_LIMIT: 'GET_POSTS_LIMIT',
  GET_POSTS_LIMIT_ADMIN: 'GET_POSTS_LIMIT_ADMIN',
  GET_NEW_POST: 'GET_NEW_POST',
  GET_NEW_POSTS: 'GET_NEW_POSTS',
  GET_POSTS_BY_CATEGORY: 'GET_POSTS_BY_CATEGORY',
  GET_HISTORY_RECHARGE: 'GET_HISTORY_RECHARGE',
  GET_HISTORY_RECHARGE_FAIL: 'GET_HISTORY_RECHARGE_FAIL',
  CLEAR_POST: 'CLEAR_POST',
  CLEAR_MSG: 'CLEAR_MSG',
  CLEAR_MSG_AUTH: 'CLEAR_MSG_AUTH',
  EDIT_POST: 'EDIT_POST',
  GET_USER_FAIL: 'GET_USER_FAIL',
  RECHARGE_DATA: 'RECHARGE_DATA',
  GET_POST_DETAIL: 'GET_POST_DETAIL',

  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_PRICES: 'GET_PRICES',
  GET_AREAS: 'GET_AREAS',
  GET_PROVINCES: 'GET_PROVINCES',
  GET_DISTRICT_BY_PROVINCES: ' GET_DISTRICT_BY_PROVINCES',
  GET_WARDS_BY_DISTRICT: 'GET_WARDS_BY_DISTRICT',
  GET_USER: 'GET_USER',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',

  GET_LOCATION: 'GET_LOCATION',

  // USER
  GET_CURRENT: 'GET_CURRENT',
};

export default actionTypes;
