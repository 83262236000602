import React from 'react';

function forgotPassword() {
  return (
    <div className="w-full flex flex-col items-center ">
      forgot Password
    </div>
  );
}

export default forgotPassword;
